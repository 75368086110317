<template>
  <div>
    <b-card
      class="mb-5"
    >
      <div class="vgt-wrap">
        <div class="vgt-inner-wrap">
          <div class="vgt-responsive">
            <table class="vgt-table bordered ">
              <thead>
                <tr>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('id')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('partner_name')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('user_id')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('username')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('reason')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('created_at')" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="user in items"
                >
                  <tr
                    :key="user.id"
                  >
                    <td class="vgt-left-align">
                      {{ user.id }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.partner_name }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.user_id }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.username }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.reason }}
                    </td>
                    <td class="vgt-left-align">
                      {{ user.created_at }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import Audit from '@/models/Audit'
import { EventBus } from '../../../eventbus'

export default {
  name: 'BanHistoryList',
  components: {
    BCard,
  },
  data() {
    return {
      items: [],
      loading: true,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const response = await Audit.banHistoryList()
      this.items = response.data.data
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
  },
}
</script>

<style>

</style>
